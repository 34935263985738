import { useEffect, useRef, useState } from 'react'; // Organize React imports first

import IconButton from '@material-ui/core/IconButton';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SquareIcon from '@mui/icons-material/Square';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Box, Grid, Typography } from '@mui/material';
import { useQuery } from 'hooks/useQuery';
import { inHomeService } from 'services';

import { DialogAlterService } from './Dialogs/DialogAlterService';
import { DialogAssignTec } from './Dialogs/DialogAssignTec';
import { DialogCancelService } from './Dialogs/DialogCancelService';
import { DialogLocation } from './Dialogs/DialogLocation';
import { DialogSelectData } from './Dialogs/DialogSelectData';
import { DialogViewInfo } from './Dialogs/DialogViewInfo';

export const AssignTecService = () => {
  const [atendimentos, setAtendimentos] = useState([]); // Estado para guardar os atendimentos
  const [services] = useQuery(() => inHomeService.findServices(), []); // Chama a API de serviços
  const calendarRef = useRef(null); // Referência para o calendário
  const [selectedEvent, setSelectedEvent] = useState(null); // Estado para o evento selecionado
  const [openModal, setOpenModal] = useState(false);
  const [openModalCancelService, setOpenModalCancelService] = useState(false);
  const [openModalAssignTec, setOpenModalAssignTec] = useState(false);
  const [openModalAlterService, setOpenModalAlterService] = useState(false);
  const [openModalViewInfo, setOpenModalViewInfo] = useState(false);
  const [openModalLocation, setOpenModalLocation] = useState(false);
  const today = new Date().toISOString().split('T')[0]; // Data de hoje formatada

  // Formata os serviços obtidos da API para serem usados no calendário
  useEffect(() => {
    if (services) {
      // Filtra os atendimentos onde active === true
      const filteredServices = services.filter((a) => a.status_id !== 3);

      const formattedServices = filteredServices.map((a) => ({
        id: a.id,
        title: `Protocolo ${a.protocol}`,
        start: a.date_Scheduling || null,
        technician: a.technician_id,
        status_id: a.status_id,
        period: a.period_schedule,
      }));

      setAtendimentos(formattedServices); // Atualiza o estado com os serviços formatados
    }
  }, [services]);

  // Separa os atendimentos com e sem data
  const atendimentosComData = atendimentos.filter((a) => a.start !== null);
  const atendimentosPendentes = atendimentos.filter((a) => a.start === null);

  // Configura o recurso de arrastar eventos
  useEffect(() => {
    let draggableEl = document.getElementById('external-events');
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: '.fc-event',
        eventData: function (eventEl) {
          const title = eventEl.getAttribute('title');
          const id = eventEl.getAttribute('data-id');
          return {
            title: title,
            id: id,
          };
        },
      });
    }
  }, []);

  // Quando um evento é arrastado e solto no calendário
  const handleEventReceive = (info) => {
    setSelectedEvent(info.event); // Define o evento selecionado
    setOpenModal(true); // Abre o modal
  };

  const handleOpenCancelService = (info_service) => {
    setSelectedEvent(info_service);
    setOpenModalCancelService(true);
  };

  const handleOpenAssignTec = (info_service) => {
    setSelectedEvent(info_service);
    setOpenModalAssignTec(true);
  };

  const handleOpenAlterService = (info_service) => {
    setSelectedEvent(info_service);
    setOpenModalAlterService(true);
  };

  const handleOpenViewInfo = (info_service) => {
    setSelectedEvent(info_service);
    setOpenModalViewInfo(true);
  };

  const handleOpenLocation = (info_service) => {
    setSelectedEvent(info_service);
    setOpenModalLocation(true);
  };

  // Função para atualizar os atendimentos após salvar as alterações no modal
  const handleSave = async () => {
    try {
      const { data } = await inHomeService.findServices();
      const filteredServices = data.filter((a) => a.status_id !== 3);
      const formattedServices = filteredServices.map((a) => ({
        id: a.id,
        title: `Protocolo ${a.protocol}`,
        start: a.date_Scheduling || null,
        technician: a.technician_id,
        status_id: a.status_id,
        period: a.period_schedule,
      }));
      setAtendimentos(formattedServices); // Atualiza o estado com os serviços formatados
    } catch (error) {
      console.error('Erro ao atualizar eventos:', error);
    }
  };

  const atendimentosFlexivel = atendimentosPendentes.filter((a) => a.period === 'Flexível');
  const atendimentosManha = atendimentosPendentes.filter((a) => a.period === 'Manhã');
  const atendimentosTarde = atendimentosPendentes.filter((a) => a.period === 'Tarde');

  return (
    <>
      <DialogSelectData
        open={openModal}
        onClose={() => setOpenModal(false)} // Fecha o modal
        selectedEvent={selectedEvent} // Passa o evento selecionado para o modal
        onSave={handleSave} // Função de salvar no modal
      />
      <DialogCancelService
        open={openModalCancelService}
        onClose={() => setOpenModalCancelService(false)}
        selectedEvent={selectedEvent}
        onSave={handleSave}
      />
      <DialogAssignTec
        open={openModalAssignTec}
        onClose={() => setOpenModalAssignTec(false)}
        selectedEvent={selectedEvent}
        onSave={handleSave}
      />
      <DialogAlterService
        open={openModalAlterService}
        onClose={() => setOpenModalAlterService(false)}
        selectedEvent={selectedEvent}
        onSave={handleSave}
      />
      <DialogViewInfo
        open={openModalViewInfo}
        onClose={() => setOpenModalViewInfo(false)}
        selectedEvent={selectedEvent}
      />
      <DialogLocation
        open={openModalLocation}
        onClose={() => setOpenModalLocation(false)}
        selectedEvent={selectedEvent}
      />
      <Grid container spacing={2}>
        {/* Painel de atendimentos pendentes */}
        <Grid item xs={3} md={3}>
          <Box
            id='external-events'
            sx={{
              padding: 2,
              border: '1px solid #ddd',
              borderRadius: 1,
              backgroundColor: '#fafafa',
              maxHeight: '500px',
              overflowY: 'auto',
            }}
          >
            <Typography variant='h6' gutterBottom>
              Pendência de Agendamento
            </Typography>

            {/* Seção Flexível */}
            <Typography variant='h6' gutterBottom>
              Flexível
            </Typography>
            {atendimentosFlexivel.map((atendimento) => (
              <Box
                key={atendimento.id}
                className='fc-event'
                title={atendimento.title}
                data-id={atendimento.id}
                sx={{
                  padding: 1.5,
                  margin: '8px 0',
                  backgroundColor: '#512da8',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>{atendimento.title}</Typography>
                <Box display='flex' justifyContent='end' alignItems='center'>
                  <Box>
                    <IconButton
                      onClick={() => handleOpenLocation(atendimento)}
                      size='small'
                      color='inherit'
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      size='small'
                      color='inherit'
                      onClick={() => handleOpenCancelService(atendimento)}
                    >
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))}

            {/* Seção Manhã */}
            <Typography variant='h6' gutterBottom>
              Manhã
            </Typography>
            {atendimentosManha.map((atendimento) => (
              <Box
                key={atendimento.id}
                className='fc-event'
                title={atendimento.title}
                data-id={atendimento.id}
                sx={{
                  padding: 1.5,
                  margin: '8px 0',
                  backgroundColor: '#512da8',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>{atendimento.title}</Typography>
                <Box display='flex' justifyContent='end' alignItems='center'>
                  <Box>
                    <IconButton
                      onClick={() => handleOpenLocation(atendimento)}
                      size='small'
                      color='inherit'
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      size='small'
                      color='inherit'
                      onClick={() => handleOpenCancelService(atendimento)}
                    >
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))}

            {/* Seção Tarde */}
            <Typography variant='h6' gutterBottom>
              Tarde
            </Typography>
            {atendimentosTarde.map((atendimento) => (
              <Box
                key={atendimento.id}
                className='fc-event'
                title={atendimento.title}
                data-id={atendimento.id}
                sx={{
                  padding: 1.5,
                  margin: '8px 0',
                  backgroundColor: '#512da8',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>{atendimento.title}</Typography>
                <Box display='flex' justifyContent='end' alignItems='center'>
                  <Box>
                    <IconButton
                      onClick={() => handleOpenLocation(atendimento)}
                      size='small'
                      color='inherit'
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      size='small'
                      color='inherit'
                      onClick={() => handleOpenCancelService(atendimento)}
                    >
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              marginTop: 2,
              padding: 2,
              borderTop: '1px solid #ddd',
              backgroundColor: '#e0e0e0',
              borderRadius: 1,
            }}
          >
            <Typography variant='h6' gutterBottom>
              Ícones
            </Typography>
            <Box display='flex' alignItems='center' mb={1}>
              <DeleteIcon fontSize='small' />
              <Typography variant='body2' sx={{ marginLeft: 1 }}>
                Cancelar Serviço
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' mb={1}>
              <SyncAltIcon fontSize='small' />
              <Typography variant='body2' sx={{ marginLeft: 1 }}>
                Alterar Data
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' mb={1}>
              <InfoIcon fontSize='small' />
              <Typography variant='body2' sx={{ marginLeft: 1 }}>
                Mais Informações
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <PersonAddIcon fontSize='small' />
              <Typography variant='body2' sx={{ marginLeft: 1 }}>
                Atribuir Técnico
              </Typography>
            </Box>
            <Box display='flex' flexDirection='row' mt={1} alignItems='center'>
              <SquareIcon style={{ color: '#6bb4fc' }} />
              <Typography variant='body2' alignItems='center'>
                Atribuido
              </Typography>
              <Grid item xs={1}></Grid>
              <SquareIcon style={{ color: '#fff' }} />
              <Typography variant='body2' alignItems='center'>
                Não Atribuído
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Calendário */}
        <Grid item xs={12} md={9}>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} // Plugins do calendário
            initialView='dayGridMonth'
            editable={false}
            droppable={true} // Permite soltar eventos no calendário
            events={atendimentosComData} // Atendimentos com data definida
            eventReceive={handleEventReceive} // Chamado ao soltar um evento no calendário
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false, // Usa o formato de 24 horas
            }}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false, // Usa o formato de 24 horas
            }}
            views={{
              timeGrid: {
                slotLabelFormat: {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false, // Usa o formato de 24 horas
                },
              },
            }}
            validRange={{
              start: today, // Bloqueia datas anteriores ao dia atual
            }}
            eventContent={(eventInfo) => (
              <Box
                sx={{
                  color: 'white',
                  backgroundColor: '#603bb8',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  padding: '8px',
                  borderRadius: 3,
                  textAlign: 'center',
                  width: '14rem',
                }}
              >
                <Typography variant='body2'>
                  <b>{eventInfo.timeText}</b>
                </Typography>
                <Typography variant='body2'>{eventInfo.event.title}</Typography>
                <IconButton
                  size='small'
                  color='inherit'
                  onClick={() => handleOpenCancelService(eventInfo.event)}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
                <IconButton
                  onClick={() => handleOpenAlterService(eventInfo.event)}
                  size='small'
                  color='inherit'
                >
                  <SyncAltIcon fontSize='small' />
                </IconButton>
                <IconButton
                  onClick={() => handleOpenViewInfo(eventInfo.event)}
                  size='small'
                  color='inherit'
                >
                  <InfoIcon fontSize='small' />
                </IconButton>
                {(!eventInfo.event.extendedProps.technician && (
                  <IconButton
                    size='small'
                    color='inherit'
                    onClick={() => handleOpenAssignTec(eventInfo.event)}
                  >
                    <PersonAddIcon fontSize='small' />
                  </IconButton>
                )) || (
                  <IconButton size='small' color='inherit'>
                    <PersonAddIcon fontSize='small' style={{ color: '#6bb4fc' }} />
                  </IconButton>
                )}
              </Box>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
