import { useRouteMatch } from 'react-router';

import { AssignTecService } from 'pages/Private/InHome/AssignTecService';
import { RegisterTechnician } from 'pages/Private/InHome/RegisterTechnician';
import { Services } from 'pages/Private/InHome/Services';
import { ServicesInBackup } from 'pages/Private/InHome/ServicesInBackup';
import { StockInHome } from 'pages/Private/InHome/StockInHome';
import { TechncianCalendar } from 'pages/Private/InHome/TechincianCalendar';

import { Route } from '../Route';

export const InHomeRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/services`} component={Services} />
      <Route isPrivate path={`${url}/register-technician`} component={RegisterTechnician} />
      <Route isPrivate path={`${url}/assign-tec-services`} component={AssignTecService} />
      <Route isPrivate path={`${url}/technician-calendar`} component={TechncianCalendar} />
      <Route isPrivate path={`${url}/stock-in-home`} component={StockInHome} />
      <Route isPrivate path={`${url}/services-in-backup`} component={ServicesInBackup} />
    </>
  );
};
