import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { TextField, Typography } from '@mui/material';
import { Button, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { inHomeService } from 'services';

export const DialogSelectData = ({ open, onClose, selectedEvent, onSave }) => {
  if (!open) return null;
  const { id } = selectedEvent;
  const handleUpdateScheduleDate = async (values) => {
    try {
      const { data } = await inHomeService.updateScheduleDate({
        id: id,
        newData: values.input_data,
      });
      toast.success('Agendamento realizado com sucesso!');
      onSave();
      onClose();
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || 'Erro ao marcar agendamento');
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Mês começa em 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  return (
    <Dialog maxWidth={'md'} open={open}>
      <Dialog.Title>Selecione a data do atendimento</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ input_data: '' }}
          onSubmit={(values) => {
            handleUpdateScheduleDate(values);
          }}
        >
          {({ values, setFieldValue, isValid, dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h6' gutterBottom>
                    Selecione a Data e Hora
                  </Typography>
                  <TextField
                    label='Data e Hora'
                    name='input_data'
                    type='datetime-local'
                    fullWidth
                    value={values.input_data}
                    onChange={(e) => setFieldValue('input_data', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: {
                        min: getCurrentDateTime(),
                      },
                    }}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button type='submit' disabled={!isValid || !dirty}>
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
