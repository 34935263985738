import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Select, Table, Text } from 'components';
import formatter from 'date-fns/format';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { inHomeService } from 'services';

import { DialogAssignBackupProduct } from './Dialogs/DialogAssignBackupProduct';

const columns = [
  { label: 'Protocolo' },
  { label: 'Produto em Reparo' },
  { label: 'Serial em Reparo' },
  { label: 'Produto de Backup' },
  { label: 'Serial de Backup' },
  { label: 'Solicitado Por' },
  { label: 'Data da Solicitação' },
  { label: 'Atribuir Produto' },
  { label: 'Finalizar Backup' },
];

export const ServicesInBackup = () => {
  const [table, setTable] = useState([]);
  const [openDialogAssign, setOpenDialogAssign] = useState(false);
  const [line, setLine] = useState();
  const [status, , ,] = useQuery(() => inHomeService.findStatusBackup(), []);

  const optionsStatus = (status || [])?.map((a) => ({
    value: a.id,
    label: a.description,
    ativo: a.active,
  }));

  const fetchData = useCallback(async (props) => {
    try {
      const { data } = await inHomeService.findServicesInBackup({
        status: props?.values?.status ? props.values.status : '',
        protocol: props?.values?.protocol ? props.values.protocol : '',
      });
      setTable(data);
    } catch (error) {
      setTable([]);
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao buscar atendimentos');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = formatter(date, 'dd/MM/yyyy HH:mm');
    return `${formattedDate}`;
  };

  const handleOpenDialogAssignProduct = async (item) => {
    setLine(item);
    setOpenDialogAssign(true);
  };
  const handleCloseDialogAssignProduct = () => {
    setOpenDialogAssign(false);
    fetchData();
  };

  const handleFinishBackup = async (item) => {
    try {
      const { data } = await inHomeService.finishBackup({
        idBackup: item.id,
        service_id: item.service_id,
        protocol: item.protocol,
        previously_status_id: item.service_status,
      });
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao finalizar atendimento');
    }
  };

  console.log(table);
  return (
    <>
      <DialogAssignBackupProduct
        open={openDialogAssign}
        onClose={() => handleCloseDialogAssignProduct()}
        line={line}
      />
      <PageTitle>Atendimento em Backup</PageTitle>
      <Formik initialValues={{ status: '', protocol: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  name='status'
                  label='Status'
                  size='small'
                  variant='outlined'
                  component={Select}
                  options={optionsStatus.filter((item) => item.ativo)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name='protocol'
                  label='Protocolo'
                  size='small'
                  variant='outlined'
                  component={Text}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={() => fetchData(props)}
                  startIcon={<SearchIcon />}
                  disabled={!props.isValid || !props.dirty}
                  fullWidth
                >
                  Filtrar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table name='table' headers={columns} size='small' disableNumeration>
                  {table.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.protocol}</TableCell>
                      <TableCell>{item.description_repair_product}</TableCell>
                      <TableCell>{item.serial_repair_product}</TableCell>
                      <TableCell>
                        {item.description_backup_product
                          ? item.description_backup_product
                          : 'Pendente'}
                      </TableCell>
                      <TableCell>
                        {item.serial_backup_product ? item.serial_backup_product : 'Pendente'}
                      </TableCell>
                      <TableCell>{item.tec}</TableCell>
                      <TableCell>{formatDate(item.created_at)}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleOpenDialogAssignProduct(item)}>
                          <AddCircleOutlineIcon style={{ color: '#512da8' }} />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleFinishBackup(item)}>
                          <DoneAllIcon style={{ color: '#f50057' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
