import { api } from 'services/api';

export class InHomeService {
  async findNfInHome(data) {
    return api.post(`/pdv/findNfInHome`, data);
  }
  async findTypeProductsPdv() {
    return api.get(`/pdv/findTypeProductsPdv`);
  }
  async createTechnician(data) {
    return api.post(`/inHome/createTechnician`, data);
  }
  async registerService(data) {
    return api.post(`/inHome/registerService`, data);
  }
  async validExistsProtocolo(data) {
    return api.post(`/inHome/validExistsProtocolo`, data);
  }
  async findInfoAdcByIdService(data) {
    return api.post(`/inHome/findInfoAdcByIdService`, data);
  }
  async findServicesByTec(data) {
    return api.post(`/inHome/findServicesByTec`, data);
  }
  async findPartByService(data) {
    return api.post(`/inHome/findPartByService`, data);
  }
  async assignTec(data) {
    return api.post(`/inHome/assignTec`, data);
  }
  async registerPartInHomeService(data) {
    return api.post(`/inHome/registerPartInHomeService`, data);
  }
  async finishedService(data) {
    return api.post(`/inHome/finishedService`, data, {
      responseType: 'blob',
    });
  }
  async requestProductBackup(data) {
    return api.post(`/inHome/requestProductBackup`, data);
  }

  async finishedStatusService(data) {
    return api.post(`/inHome/finishedStatusService`, data);
  }
  async uploadImagesAws(data) {
    return api.post(`/inHome/uploadImagesAws`, data);
  }
  async findVincsProdTecById(data) {
    return api.post(`/inHome/findVincsProdTecById`, data);
  }
  async checkIn(data) {
    return api.post(`/inHome/checkIn`, data);
  }
  async checkOut(data) {
    return api.post(`/inHome/checkOut`, data);
  }
  async updateService(data) {
    return api.put(`/inHome/updateService`, data);
  }
  async satisfactionSearch(data) {
    return api.post(`/inHome/satisfactionSearch`, data);
  }
  async findServicesInBackup(data) {
    return api.post(`/inHome/findServicesInBackup`, data);
  }
  async findStatusBackup(data) {
    return api.post(`/inHome/findStatusBackup`, data);
  }
  async finishBackup(data) {
    return api.post(`/inHome/finishBackup`, data);
  }
  async findVincsProdTecByIdProd(data) {
    return api.post(`/inHome/findVincsProdTecByIdProd`, data);
  }
  async assignBackupProduct(data) {
    return api.put(`/inHome/assignBackupProduct`, data);
  }
  async findInHomeTec() {
    return api.get(`/inHome/findInHomeTec`);
  }
  async findServices() {
    return api.get(`/inHome/findServices`);
  }
  async updateTechnician(data) {
    return api.put(`/inHome/updateTechnician`, data);
  }
  async updateUsedAmount(data) {
    return api.put(`/inHome/updateUsedAmount`, data);
  }
  async desactiveTechnician(data) {
    return api.put(`/inHome/desactiveTechnician`, data);
  }
  async desactiveService(data) {
    return api.put(`/inHome/desactiveService`, data);
  }
  async updateScheduleDate(data) {
    return api.put(`/inHome/updateScheduleDate`, data);
  }
  async deletePartInHomeService(data) {
    return api.post(`/inHome/deletePartInHomeService`, data);
  }
}

export const inHomeService = new InHomeService();
