import { CiAlarmOn } from 'react-icons/ci';
import { GiMailbox } from 'react-icons/gi';
import { ImDrawer, ImUserCheck } from 'react-icons/im';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HomeIcon from '@material-ui/icons/Home';

import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BlockIcon from '@mui/icons-material/Block';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CallIcon from '@mui/icons-material/Call';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import DevicesIcon from '@mui/icons-material/Devices';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TaskIcon from '@mui/icons-material/Task';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const iconMap = {
  HomeIcon: <HomeIcon />,
  AssignmentTurnedInIcon: <AssignmentTurnedInIcon />,
  AttachMoneyIcon: <AttachMoneyIcon />,
  SupervisorAccountIcon: <SupervisorAccountIcon />,
  ReceiptLongIcon: <ReceiptLongIcon />,
  AssessmentIcon: <AssessmentIcon />,
  WarehouseIcon: <WarehouseIcon />,
  StoreIcon: <StoreIcon />,
  ShoppingCartIcon: <ShoppingCartIcon />,
  ScreenShareIcon: <ScreenShareIcon />,
  TaskIcon: <TaskIcon />,
  BlockIcon: <BlockIcon />,
  ContentPasteGoIcon: <ContentPasteGoIcon />,
  ManageSearchIcon: <ManageSearchIcon />,
  PersonAddIcon: <PersonAddIcon />,
  ReceiptIcon: <ReceiptIcon />,
  SummarizeIcon: <SummarizeIcon />,
  LocalShippingIcon: <LocalShippingIcon />,
  CallIcon: <CallIcon />,
  DevicesIcon: <DevicesIcon />,
  GiMailbox: <GiMailbox />,
  ImDrawer: <ImDrawer />,
  ImUserCheck: <ImUserCheck />,
  PlaylistAddCheckIcon: <PlaylistAddCheckIcon />,
  DoneAllIcon: <DoneAllIcon />,
  CiAlarmOn: <CiAlarmOn />,
  ContactPhoneIcon: <ContactPhoneIcon />,
  HowToRegIcon: <HowToRegIcon />,
  CalendarTodayIcon: <CalendarTodayIcon />,
  Inventory2Icon: <Inventory2Icon />,
  SettingsBackupRestoreIcon: <SettingsBackupRestoreIcon />,
};

export const iconSwitch = (roles) => {
  switch (roles) {
    case 1:
      return iconMap.HomeIcon;
    case 3:
      return iconMap.AttachMoneyIcon;
    case 14:
      return iconMap.SupervisorAccountIcon;
    case 17:
      return iconMap.ReceiptLongIcon;
    case 15:
      return iconMap.AssessmentIcon;
    case 4:
      return iconMap.WarehouseIcon;
    case 18:
      return iconMap.StoreIcon;
    case 19:
      return iconMap.ShoppingCartIcon;
    case 20:
      return iconMap.TaskIcon;
    case 300:
      return iconMap.BlockIcon;
    case 500:
    case 501:
      return iconMap.ContentPasteGoIcon;
    case 12:
      return iconMap.LocalShippingIcon;
    case 600:
      return iconMap.ManageSearchIcon;
    case 100:
    case 102:
    case 101:
      return iconMap.PersonAddIcon;
    case 104:
    case 105:
    case 106:
    case 107:
    case 108:
    case 109:
    case 110:
    case 111:
    case 112:
    case 113:
    case 114:
    case 115:
    case 116:
    case 117:
    case 118:
    case 119:
    case 120:
    case 121:
    case 122:
    case 123:
    case 124:
    case 125:
    case 126:
    case 127:
    case 128:
    case 129:
    case 130:
    case 131:
    case 132:
    case 133:
    case 134:
    case 135:
    case 136:
    case 137:
    case 138:
    case 139:
    case 140:
    case 141:
    case 142:
    case 143:
    case 144:
    case 145:
    case 146:
    case 147:
    case 148:
    case 149:
      return iconMap.SummarizeIcon;
    case 13:
      return iconMap.CallIcon;
    case 11:
      return iconMap.DevicesIcon;
    case 12:
    case 20:
      return iconMap.GiMailbox;
    case 7:
      return iconMap.ImDrawer;
    case 10:
      return iconMap.ImUserCheck;
    case 19:
      return iconMap.PlaylistAddCheckIcon;
    case 182:
    case 192:
    case 199:
    case 200:
    case 201:
    case 202:
    case 203:
    case 204:
    case 205:
    case 206:
    case 207:
    case 208:
    case 209:
    case 210:
    case 211:
    case 212:
    case 213:
    case 214:
    case 215:
    case 216:
    case 217:
    case 218:
      return iconMap.DoneAllIcon;
    case 140:
      return iconMap.CiAlarmOnIcon;
    case 250:
    case 251:
    case 252:
    case 253:
      return iconMap.AssignmentTurnedInIcon;
    case 700:
      return iconMap.ContactPhoneIcon;
    case 701:
      return iconMap.HowToRegIcon;
    case 702:
      return iconMap.PersonAddIcon;
    case 703:
      return iconMap.CalendarTodayIcon;
    case 704:
      return iconMap.Inventory2Icon;
    case 705:
      return iconMap.SettingsBackupRestoreIcon;
    default:
      return null; // ícone padrão ou nulo caso o papel não seja encontrado
  }
};
