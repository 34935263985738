import { useCallback, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog } from 'components';
import { inHomeService } from 'services';

export const DialogTecsByProd = ({ open, onClose, info }) => {
  if (!open) return null;

  const [tecs, setTecs] = useState([]);

  const fetchData = useCallback(async () => {
    const { data } = await inHomeService.findVincsProdTecByIdProd({
      prod: info.dataNf[0].group_prod,
    });
    setTecs(data);
  }, [info.dataNf]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Dialog open={open} maxWidth={'md'}>
      <Dialog.Title onClose={onClose}>
        Técnicos com especialidade em {info.dataNf[0].group_prod}
      </Dialog.Title>
      <Dialog.Content>
        {tecs.length > 0 ? (
          <Grid container spacing={2}>
            {tecs.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box border={1} borderRadius={4} p={2} textAlign='center' color='#512da8'>
                  <Typography variant='h6'>{item.tec.name}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant='body1' color='textSecondary'>
            Nenhum técnico encontrado para o produto {info.dataNf[0].group_prod}.
          </Typography>
        )}
      </Dialog.Content>
    </Dialog>
  );
};
