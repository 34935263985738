import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import SearchIcon from '@mui/icons-material/Search';
import { Button, CustomAutocomplete, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogAssignTec = ({ open, onClose, selectedEvent, onSave }) => {
  if (!open) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [infoAdc, setInfoAdc] = useState([]);
  const [vincProd, setVincProd] = useState([]);
  const [tecs, , ,] = useQuery(() => inHomeService.findInHomeTec(), []);

  const optionsTecs = (tecs || [])?.map((a) => ({
    value: a.user_id.id,
    label: a.user_id.name,
    ativo: a.active,
  }));

  const filterActiveTecs = optionsTecs.filter((item) => item.ativo);

  const handleGetInfoAdcTec = async (props) => {
    const infoAdc = tecs.filter((item) => item.user_id.id === props.values.input_tec.value);
    const { data } = await inHomeService.findVincsProdTecById({ id: props.values.input_tec.value });
    setInfoAdc(infoAdc);
    setVincProd(data);
  };
  const handleAssignTec = async (values) => {
    try {
      const { data } = await inHomeService.assignTec({
        id_service: selectedEvent.id,
        id_tec: values.input_tec.value,
        name_tec: values.input_tec.label,
        protocol: selectedEvent.title,
        previously_id: selectedEvent.extendedProps.status_id,
        user_assign: user.id,
        date_schedule: selectedEvent.start,
      });
      toast.success('Técnico atribuído com sucesso.');
      onClose();
      onSave();
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || 'Erro atribuir técnico');
    }
  };

  const specialitys = vincProd.map((item) => item.product).join(', ');

  return (
    <>
      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title onClose={onClose}>Atribuir Técnico</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ input_tec: '' }} onSubmit={handleAssignTec}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <CustomAutocomplete
                      label='Técnico'
                      name='input_tec'
                      filteredValues={filterActiveTecs.filter((item) => item.label) || []}
                      textFieldProps={{
                        fullWidth: true,
                        variant: 'outlined',
                      }}
                      value={props.values.input_tec ? props.values.input_tec.label : ''}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton>
                      <SearchIcon
                        onClick={() => handleGetInfoAdcTec(props)}
                        style={{ color: '#512da8' }}
                      />
                    </IconButton>
                  </Grid>
                  {infoAdc.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Card>
                          <CardHeader title='Informações do Técnico' />
                          <Divider />
                          <CardContent>
                            <Box display={'flex'} flexDirection={'row'}>
                              <Grid container spacing={2}>
                                <Grid item xs={2}>
                                  <Typography variant='body1'>
                                    <strong>Jornada: {infoAdc[0].journey}</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant='body1'>
                                    <strong>Pode fazer extra? {infoAdc[0].extra}</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant='body1'>
                                    <strong>Especialidades do técnico:</strong>{' '}
                                    {specialitys ? specialitys : ''}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6}>
                    <Button onClick={onClose} color='secondary' fullWidth>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button disabled={infoAdc.length === 0} fullWidth type='submit'>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
