import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { LeaderScreen } from './LeaderScreen';
import { TechnicianScreen } from './TechnicianScreen';

export const TechncianCalendar = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const roleId = user.role.id;

  const [tecs, , ,] = useQuery(() => inHomeService.findInHomeTec(), []);

  const optionsTecs = (tecs || [])?.map((a) => ({
    value: a.user_id.id,
    label: a.user_id.name,
    ativo: a.active,
  }));

  let tecOrAdmin;
  let isTec;
  if (optionsTecs.some((item) => item.value === user.id)) {
    tecOrAdmin = `Técnico: ${user.name} `;
    isTec = true;
  } else {
    if (roleId === 8) {
      tecOrAdmin = `Administrador: ${user.name} `;
      isTec = false;
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
            Agenda In Home - {tecOrAdmin}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isTec && (
            <>
              <TechnicianScreen />
            </>
          )}
          {!isTec && (
            <>
              <LeaderScreen />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
