import { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, DatePicker, Select, Text, Time } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { NewServiceContext } from '../..';

export const ObsService = ({ protocolData }) => {
  const { updateProtocolData, setActiveStep } = useContext(NewServiceContext);

  const typePeriod = [
    { label: 'Manhã', value: 'Manhã' },
    { label: 'Tarde', value: 'Tarde' },
    { label: 'Flexível', value: 'Flexível' },
  ];

  const handleSubmit = async (values) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await updateProtocolData({
        obs: values.input_obs,
        date: values.input_data || null,
        time: values.time || null,
        period: values.period,
      });
      setActiveStep(3);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao salvar observação');
    }
  };

  return (
    <>
      <PageTitle> Agendamento </PageTitle>

      <Formik
        initialValues={{ input_obs: '', input_data: null, time: null }}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <Grid item xs={12}>
              <Typography variant='h6'>
                <strong>Protocolo: {protocolData.protocol}</strong>
              </Typography>
            </Grid>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Field
                    size='small'
                    variant='inline'
                    fullWidth
                    inputVariant='outlined'
                    label='Data Atendimento'
                    name='input_data'
                    component={DatePicker}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    size='small'
                    // loading={isLoading}
                    name='time'
                    variant='outlined'
                    fullWidth
                    inputVariant='outlined'
                    label='Horário'
                    component={Time}
                    defaultValue='00:00'
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    size='small'
                    name='period'
                    label='Período'
                    variant='outlined'
                    options={typePeriod}
                    component={Select}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    size='small'
                    loading={props.isSubmitting}
                    name='input_obs'
                    variant='outlined'
                    fullWidth
                    label='Motivo do atendimento'
                    component={Text}
                    multiline
                    minRows={10}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Button
                    type='submit'
                    disabled={!props.isValid || !props.dirty}
                    loading={props.isSubmitting}
                    endIcon={<ArrowForwardIcon />}
                    fullWidth
                  >
                    Avançar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
