import { api } from 'services/api';

class GoogleService {
  // async fetchUrl(url, parameters) {
  //   let stringParam = '';
  //   for (let i = 0; i < parameters.length; i++) {
  //     if (i === 0) stringParam += '?';
  //     else stringParam += '&';

  //     stringParam += parameters[i].key + '=' + parameters[i].value;
  //   }

  //   const response = await api.get(`${url}${stringParam}`);
  //   return response.data;
  // }

  // async getDirections(origin, destination) {
  //   return this.fetchUrl('https://maps.googleapis.com/maps/api/directions/json', [
  //     { key: 'key', value: this.key },
  //     { key: 'origin', value: origin },
  //     { key: 'destination', value: destination },
  //   ]);
  // }

  async getDirections(data) {
    return api.post(`/api-google/getDirections`, data);
  }
}

export const googleService = new GoogleService();
