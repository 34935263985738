import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Mask, Text } from 'components';
import { MapRoutes } from 'components/MapRoutes/indes';
import { Field, Form, Formik } from 'formik';
import { formatDateDirect } from 'helpers/formateDate';
import { PageTitle } from 'pages/Layout/PageTitle';
import { inHomeService } from 'services';

import { NewServiceContext } from '../..';

export const GetNfForm = () => {
  const { updateProtocolData, setActiveStep } = useContext(NewServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await updateProtocolData({ dataNf: newData });
      setActiveStep(2);
      setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao salvar dados da nota');
      setIsLoading(false);
    }
  };

  const [newData, setNewData] = useState([]);

  const origin = 'Avenida Liberdade, 4565, Sorocaba';
  const destination =
    newData[0]?.address +
    ' ' +
    newData[0]?.neighborhood +
    ' ' +
    newData[0]?.address_number +
    ' ' +
    newData[0]?.city +
    ' ' +
    newData[0]?.state;
  console.log(destination);

  const handleGetNfPdv = async (values) => {
    try {
      // Simula um delay de 3 segundos
      await new Promise((resolve) => setTimeout(resolve, 3000));

      // Filtra a nota fiscal com base no valor informado

      const { data } = await inHomeService.findNfInHome({ serial: values.serial });

      if (data.length > 0) {
        setNewData(data);
      } else {
        // Caso a nota não seja encontrada
        toast.error('Serial não encontrado');
        console.error('Serial não encontrado');
      }
    } catch (error) {
      // Tratamento de erro com log para diagnóstico
      console.error('Erro ao buscar Serial:', error?.response?.data?.message || '');
      toast.error(error?.response?.data?.message || 'Erro ao buscar Serial');
    }
  };

  console.log(newData);
  return (
    <>
      <Box component={Paper} boxShadow={2} p={3} mb={4}>
        <PageTitle>Buscar Serial</PageTitle>
        <Formik initialValues={{ serial: '' }} onSubmit={handleGetNfPdv}>
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    size='small'
                    loading={props.isSubmitting || isLoading}
                    name='serial'
                    variant='outlined'
                    fullWidth
                    label='Serial'
                    component={Text}
                    onInput={toTrim}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    loading={props.isSubmitting || isLoading}
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    disabled={!props.isValid || !props.dirty}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      {newData.length > 0 && (
        <Box>
          <Typography variant='h5' gutterBottom>
            Informações da Nota Fiscal
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant='h6' color='textSecondary' gutterBottom>
                    Cliente
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Nome:</strong> {newData[0].name}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Email:</strong> {newData[0].email}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>CPF/CNPJ:</strong> <Mask type='document'>{newData[0].cgc}</Mask>
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Celular:</strong> <Mask type='phone'>{newData[0].cellphone}</Mask>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant='h6' color='textSecondary' gutterBottom>
                    Nota Fiscal
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Nota:</strong> {newData[0].numero_nota_fiscal}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Série:</strong> {newData[0].serie}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Chave:</strong>
                  </Typography>
                  <Typography
                    style={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}
                    variant='body1'
                  >
                    {newData[0].chave_de_acesso}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Data de Emissão:</strong> {formatDateDirect(newData[0].created_at)}
                  </Typography>
                  <Typography align='left' variant='body1'>
                    <strong>Produto: </strong>
                  </Typography>
                  <Typography align='left' variant='body2'>
                    {newData[0].descricao}
                  </Typography>
                  <Typography align='left' variant='body1'>
                    <strong>Serial: </strong>
                  </Typography>
                  <Typography align='left' variant='body2'>
                    {newData[0].serial}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant='h6' color='textSecondary' gutterBottom>
                    Endereço
                  </Typography>
                  <Typography variant='body1'>
                    <strong>CEP:</strong> {newData[0].zip_code}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Estado:</strong> {newData[0].state}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Cidade:</strong> {newData[0].city}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Bairro:</strong> {newData[0].neighborhood}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Rua:</strong> {newData[0].address}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Número:</strong> {newData[0].address_number}
                  </Typography>
                  <Typography variant='body1'>
                    <strong>Complemento:</strong> {newData[0].complement}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <MapRoutes origin={origin} destination={destination}></MapRoutes>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                onClick={() => handleSubmit()}
                endIcon={<ArrowForwardIcon />}
                fullWidth
                loading={isLoading}
              >
                Avançar
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
